<template>
  <button @click="handleClick" :data-umami-event="`button_${text}`">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "customButton",

  props: {
    text: {
      type: String,
      required: true,
    },
    href: {
      type: String,
    },
    scroll: {
      type: String,
    },
  },
  emits: {
    click: null,
  },
  methods: {
    handleClick() {
      if (this.href) {
        window.open(this.href, "_blank");
      }

      if (this.scroll) {
        let page = document.querySelector("#page-view");
        page.scrollTo({
          top: innerHeight,
          behavior: "smooth",
        });
      }

      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  color: #000;
  background-color: #f6e074;
  border: 0;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1.1rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #3f3d56;
    color: #fff;
  }
}
</style>
