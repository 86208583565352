<template>
  <div id="get-started" class="section page">
    <div class="li">
      <img src="@/assets/svg/undraw_shopping_re_hdd9.svg" alt="" />
    </div>
    <div class="li">
      <div class="sub-hedding">ABOUT US</div>
      <div class="hedding">What we are all about</div>
      <div class="section-content">
        We specialize in
        <span class="colored">design</span>
        , <span class="colored">marketing</span>, and comprehensive
        <span class="colored">IT solutions</span>, empowering businesses to
        thrive in today's digital landscape through innovative strategies and
        exceptional support tailored to your unique needs.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>
