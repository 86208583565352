<template>
  <div class="section page">
    <div class="li">
      <img src="@/assets/svg/undraw_contract_re_ves9.svg" alt="" />
    </div>
    <div class="li">
      <div class="sub-hedding">CONTACT US</div>
      <div class="hedding">Let’s get in touch</div>
      <div class="section-content">
        Send us your details and we'll get back to you as soon as possible.
        <div class="form">
          <transition name="fade-in-out">
            <div class="error notification" v-if="error">
              <span class="icon error-icon">
                <i class="fa-solid fa-exclamation"></i>
              </span>
              {{ error }}
            </div>
          </transition>
          <transition name="fade-in-out">
            <div class="success notification" v-if="success">
              <span class="icon success-icon">
                <i class="fa-solid fa-check"></i>
              </span>
              {{ success }}
            </div>
          </transition>
          <custom-input
            label="Name"
            placeholder="Enter your name"
            v-model="name"
            icon="fa-regular fa-user"
          />
          <custom-input
            label="Email or Phone"
            placeholder="Enter your email or phone"
            v-model="contact"
            icon="fa-regular fa-envelope"
          />
          <vue-turnstile site-key="0x4AAAAAAAyjB2SGFmmb1raN" v-model="token" />
          <div class="action">
            <custom-button @click="submit" text="Submit" />
          </div>
        </div>
        Alternatively, you can send us an email at
        <a class="colored" href="mailto:contact@honex.life"
          >contact@honex.life</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import customButton from "./custom/customButton.vue";
import customInput from "./custom/customInput.vue";
import VueTurnstile from "vue-turnstile";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    customButton,
    customInput,
    VueTurnstile,
  },
  data() {
    return {
      name: "",
      contact: "",
      token: "",
      error: "",
      success: "",
    };
  },
  methods: {
    submit() {
      axios
        .post("https://api.honex.life/api/site-form", {
          name: this.name,
          contact: this.contact,
          token: this.token,
        })
        .then((res) => {
          this.error = "";
          if (res.data.success) {
            this.name = "";
            this.contact = "";
            this.token = "";
            this.success = res.data.message;
          }
        })
        .catch((err) => {
          this.error = err.response.data.message;
          this.success = "";
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.fade-in-out-enter-active,
.fade-in-out-leave-active {
  transition: all 0.2s ease-in-out;
}
.fade-in-out-enter-from,
.fade-in-out-leave-to {
  opacity: 0;
}

.notification {
  font-size: 1.2rem;
  padding: 10px;
  display: flex;
  font-weight: 600;
  align-items: center;
  border-radius: 15px;
  .icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
}

.error {
  color: whitesmoke;
  background-color: rgb(255, 122, 122);
  .error-icon {
    background-color: whitesmoke;
    color: rgb(255, 122, 122);
  }
}

.success {
  color: black;
  background-color: rgb(122, 255, 122);
  .success-icon {
    background-color: black;
    color: rgb(122, 255, 122);
  }
}
</style>
