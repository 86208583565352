<template>
  <div class="section page reverse">
    <div class="li">
      <div class="hedding">
        We would love to be a part of your <span class="colored">BRAND</span>
      </div>
      <div class="section-content">
        We offer expert design, marketing, and IT solutions to enhance your
        business's online presence and success.
      </div>
      <div class="action">
        <custom-button text="Get Started" scroll="true" />
      </div>
    </div>
    <div class="li">
      <img src="@/assets/svg/undraw_designer_girl_re_h54c.svg" alt="" />
    </div>
  </div>
</template>

<script>
import customButton from "@/components/custom/customButton.vue";
export default {
  name: "HomeView",
  components: {
    customButton,
  },
};
</script>
