<template>
  <div>
    <LandingComponent />
    <AboutUs />
    <!-- <GuaranteesView /> -->
    <ServicesView />
    <ContactUs />
  </div>
</template>

<script>
import LandingComponent from "../components/LandingComponent.vue";
import AboutUs from "../components/AboutUs.vue";
// import GuaranteesView from "../components/GuaranteesPage.vue";
import ServicesView from "../components/ServicesView.vue";
import ContactUs from "@/components/ContactUs.vue";
export default {
  name: "HomeView",
  components: {
    LandingComponent,
    AboutUs,
    // GuaranteesView,
    ServicesView,
    ContactUs,
  },
};
</script>

<style lang="scss" scoped></style>
