<template>
  <nav :class="scrolled">
    <router-link to="/">
      <img src="@/assets/logo.png" alt="" />
      <span :class="`logo ${scrolled}`">Honex</span>
    </router-link>

    <div class="right">
      <custom-dropdown title="Customer Portals" :items="customerPortals" />
    </div>
  </nav>
</template>

<script>
import customDropdown from "./custom/customDropdown.vue";
export default {
  name: "NavBar",
  components: { customDropdown },
  data() {
    return {
      scrolled: "",
      customerPortals: [
        {
          title: "Dashboard",
          link: "https://dash.honex.life/",
          target: "_blank",
        },
        {
          title: "Plesk",
          link: "https://plesk.honex.life/",
          target: "_blank",
        },
        {
          title: "Umami",
          link: "https://umami.honex.life/",
          target: "_blank",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = "scrolled";
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: whitesmoke;
  z-index: 999;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  .right {
    display: flex;
    align-items: center;
    margin-right: 30px;
    // Hide on screen smaller than 500px
    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }
  span {
    font-size: 40px;
    font-weight: bold;
    &.logo {
      margin-left: -10px;
    }
    &.scrolled {
      display: none;
    }
  }
}
</style>
