<template>
  <NavBar />
  <div id="page-view" class="page-view">
    <router-view />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  name: "App",
  components: {
    NavBar,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");
@import "@/assets/scss/scrollbar.scss";

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  background-color: rgb(245, 245, 245);
}

.page-view {
  height: calc(100vh - 85px);
  overflow-y: scroll;
  margin-top: 85px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
}

.page {
  min-height: calc(100vh - 170px);
  max-width: 1200px;
}

.section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  // On a screen smaller than 500px change the layout
  @media screen and (max-width: 850px) {
    flex-direction: column;
    &.reverse {
      flex-direction: column-reverse !important;
    }
  }
  .li {
    width: 50%;
    padding: 10px;
    @media screen and (max-width: 850px) {
      width: 100%;
      height: 400px;
    }
    .action {
      margin-top: 20px;
    }
    img {
      width: 100%;
      @media screen and (max-width: 850px) {
        height: 400px;
      }
    }
  }
}

.section-content {
  font-size: 1.2rem;
  line-height: 1.5;
}

.sub-hedding {
  margin: 20px 0;
  border-bottom: 1px solid #000;
}

.hedding {
  font-size: 2.5rem;
  font-weight: 600;
  font-family: "Oxygen", sans-serif;
}
.colored {
  color: #e2bc00;
  font-weight: 600;
}

.pink {
  background-color: #f5e0e8;
}

.yellow {
  background-color: #f8efb3;
}

.blue {
  background-color: #e2f2f5;
}
</style>
