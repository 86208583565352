import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import titleMixin from "./plugins/titleMixin";

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    titleMixin.setTitle(to.meta.title);
  }
  next();
});

createApp(App).use(router).mount("#app");
