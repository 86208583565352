<template>
  <div class="group">
    <label class="label" :for="label">{{ label }}</label>
    <i :class="`icon ${icon}`"></i>
    <input
      class="input"
      :type="type"
      :placeholder="placeholder"
      :id="label"
      v-model="inputValue"
    />
  </div>
</template>

<script>
export default {
  name: "customInput",

  props: {
    icon: {
      typre: String,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
  },

  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
/* From Uiverse.io by ozgeozkaraa01 */
.group {
  display: flex;
  line-height: 30px;
  align-items: center;
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.label {
  position: absolute;
  top: -1.3rem;
  left: 1rem;
  font-size: 0.8rem;
  padding: 0 0.2rem;
}

.input {
  width: 100%;
  height: 45px;
  line-height: 30px;
  padding: 0 5rem;
  padding-left: 3rem;
  border: 2px solid transparent;
  border-radius: 10px;
  outline: none;
  background-color: #f8fafc;
  color: #0d0c22;
  transition: 0.5s ease;
}

.input::placeholder {
  color: #94a3b8;
}

.input:focus,
input:hover {
  outline: none;
  border-color: #f6e074;
  background-color: #fff;
  box-shadow: 0 0 0 5px #f6e074a9;
}

.icon {
  position: absolute;
  left: 1rem;
  fill: none;
  width: 1rem;
  height: 1rem;
}
</style>
