<template>
  <div class="section page reverse">
    <div class="li">
      <div class="sub-hedding">SERVICES</div>
      <div class="hedding">What can we do for you?</div>
      <div class="section-content">
        <div class="listing">
          <div class="list-item" v-for="item in items" :key="item.title">
            <div
              @click="expanded = expanded == item.title ? null : item.title"
              class="expand"
            >
              {{ item.title }}
            </div>
            <Transition name="fade-in-out" mode="out-in">
              <div v-if="expanded == item.title" class="expanded">
                {{ item.text }}
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </div>
    <div class="li">
      <img src="@/assets/svg/undraw_search_app_oso2.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesView",
  data() {
    return {
      expanded: null,
      items: [
        {
          title: "Branding",
          text: "Elevate your business with our comprehensive design, marketing, and IT services, tailored to enhance your brand's presence and drive success.",
        },
        {
          title: "Marketing",
          text: "Empowering businesses through innovative design, strategic marketing, and comprehensive IT solutions for exceptional growth and success. Transform your vision into reality with us.",
        },
        {
          title: "Social Media",
          text: "Enhance your brand's presence with our design, marketing, and IT solutions, tailored for impactful growth through strategic social media engagement.",
        },
        {
          title: "IT Solutions",
          text: "Streamline your business operations with our design, marketing, and IT solutions, tailored to elevate your company's success.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.expand {
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
  border-bottom: 1px solid #f6e074;
  padding: 10px 0;
}

.fade-in-out-enter-active,
.fade-in-out-leave-active {
  transition: all 0.2s ease-in-out;
}
.fade-in-out-enter-from,
.fade-in-out-leave-to {
  opacity: 0;
}
</style>
